import React from 'react';
import InfosContainer from '../containers/InfosContainer';
import routes from '../../../Routes';
import Layout from '../../../layouts/index';

const nextStep = routes.FastVariantSuccess.url;
const Step5Infos = () => (
  <Layout slug="FastVariantInfos">
    <InfosContainer nextStep={nextStep} />
  </Layout>
);
export default Step5Infos;
